import { useNavigate, useLocation } from 'react-router-dom';
import ShimmerButton from '../components/magicui/shimmer-button';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Récupérer les paramètres de l'URL
  const queryParams = new URLSearchParams(location.search);
  const purchaseType = queryParams.get('purchaseType');

  // Déterminer le contenu de la modale en fonction du type d'achat
  const getModalContent = () => {
    if (purchaseType === 'bilan') {
      return (
        <>
          <h2 className="text-2xl font-bold mb-4">Merci pour votre confiance !</h2>
          <p className="mb-4">
            Vous allez immédiatement recevoir un mail<br />
            avec votre budget prévisionnel à 3 ans.
          </p>
          <p>
            Vous souhaitez obtenir les statuts pour votre société ? <br/>
            Rendez-vous dans l'onglet STATUTS.
          </p>
          <div className="flex justify-center space-x-4"> {/* Ajout de 'space-x-4' pour l'espacement */}
            <ShimmerButton
              className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl"
              onClick={() => navigate('/')}
            >
              Retour à l'accueil
            </ShimmerButton>
            <ShimmerButton
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl"
              onClick={() => navigate('/statuts')}
            >
              Obtenir mes STATUTS
            </ShimmerButton>
          </div>
        </>
      );
    } else if (purchaseType === 'statuts') {
      return (
        <>
          <h2 className="text-2xl font-bold mb-4">Merci pour votre confiance !</h2>
          <p className="mb-4">
            Vous allez immédiatement recevoir un mail<br />
            avec vos statuts.
          </p>
          <div className="flex justify-center">
            <ShimmerButton
              className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl"
              onClick={() => navigate('/')}
            >
              Retour à l'accueil
            </ShimmerButton>
          </div>
        </>
      );
    } else if (purchaseType === 'statuts') {
      return (
        <>
          <h2 className="text-2xl font-bold mb-4">Merci pour votre confiance !</h2>
          <p className="mb-4">
            Vous allez immédiatement recevoir un mail<br />
            avec vos statuts.
          </p>
          <div className="flex justify-center">
            <ShimmerButton
              className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              onClick={() => navigate('/')}
            >
              Retour à l'accueil
            </ShimmerButton>
          </div>
        </>
      );
    }
    // Contenu par défaut si `purchaseType` n'est pas défini
    return (
      <>
        <h2 className="text-2xl font-bold mb-4">Merci pour votre confiance !</h2>
        <p className="mb-4">
          Vous allez immédiatement recevoir un mail.
        </p>
        <div className="flex justify-center">
          <ShimmerButton
            className="bg-purple-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            onClick={() => navigate('/')}
          >
            Retour à l'accueil
          </ShimmerButton>
        </div>
      </>
    );
  };

  return (
    <div className="success-background flex items-center justify-center min-h-screen">
      <div className="bg-white p-8 rounded shadow-md text-center">
        {getModalContent()}
      </div>
    </div>
  );
};

export default Success;
