import { useNavigate } from 'react-router-dom';

export const CookieBanner: React.FC<{ onAccept: () => void, onReject: () => void }> = ({ onAccept, onReject }) => {
    const handleReject = () => {
        window.open('https://www.cnil.fr/fr/cookies-et-autres-traceurs', '_blank');
    };

    return (
        <>
            {/* Overlay transparent qui bloque les interactions */}
            <div 
                className="fixed inset-0 z-[999] bg-transparent" 
                style={{ pointerEvents: 'all' }}
            />
            
            {/* Bannière de cookies */}
            <div className="fixed inset-x-0 bottom-[60px] sm:bottom-[52px] mx-auto p-4 bg-white border border-gray-200 shadow-lg z-[1000]">
                <div className="max-w-7xl mx-auto">
                    <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                        <p className="text-sm text-gray-600 text-center sm:text-left">
                            Pour continuer à utiliser ce site, veuillez accepter ou refuser les cookies. 
                            Nous les utilisons pour améliorer votre expérience.
                        </p>
                        <div className="flex gap-2 w-full sm:w-auto">
                            <button 
                                className="flex-1 sm:flex-none px-6 py-2 bg-[#4B7BF5] text-white rounded-lg hover:bg-[#3D63D2] transition-colors font-medium" 
                                onClick={onAccept}
                            >
                                Accepter
                            </button>
                            <button 
                                className="flex-1 sm:flex-none px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors font-medium" 
                                onClick={handleReject}
                            >
                                Refuser
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
