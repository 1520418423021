import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ShimmerButton from '../components/magicui/shimmer-button';

const ChevronIcon = ({ isOpen }: { isOpen: boolean }) => (
    <svg 
        width={16} 
        height={16} 
        className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        fill="none" 
        stroke="#1D63FF" 
        strokeWidth={3} 
        viewBox="0 0 24 24"
    >
        <path d="m6 9 6 6 6-6" />
    </svg>
);

export const Previsionnel = () => {
    const [isOpenLeft, setIsOpenLeft] = useState(false);
    const [isOpenRight, setIsOpenRight] = useState(false);
    const navigate = useNavigate();

    const toggleOpenLeft = () => {
        setIsOpenLeft(!isOpenLeft);
    };

    const toggleOpenRight = () => {
        setIsOpenRight(!isOpenRight);
    };

    return (
        <div className='min-h-screen bg-gradient-to-b from-white to-gray-50 pt-6 sm:pt-12 md:pt-24'>
            <div className="max-w-5xl mx-auto px-4 flex flex-col gap-6">
                {/* Section Questions/Réponses */}
                <div className="grid md:grid-cols-2 gap-6">
                    {/* Première carte */}
                    <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200">
                        <button 
                            onClick={toggleOpenLeft}
                            className="w-full flex items-center justify-between group"
                        >
                            <h2 className="text-lg font-semibold text-gray-900 group-hover:text-[#1D63FF] transition-colors text-left pr-4">
                                Pourquoi avoir besoin d'un budget prévisionnel ?
                            </h2>
                            <ChevronIcon isOpen={isOpenLeft} />
                        </button>
                        
                        <AnimatePresence>
                            {isOpenLeft && (
                                <motion.div 
                                    initial={{ height: 0, opacity: 0 }} 
                                    animate={{ height: 'auto', opacity: 1 }} 
                                    exit={{ height: 0, opacity: 0 }} 
                                    transition={{ duration: 0.2 }}
                                    className="overflow-hidden"
                                >
                                    <div className="mt-6 space-y-4 text-gray-600">
                                        <p className="flex gap-3 items-start">
                                            <span className="text-[#1D63FF] text-sm">●</span>
                                            <span>Un budget prévisionnel est conseillé lors d'une création d'entreprise afin d'obtenir
                                            <strong className="text-gray-900"> une prévision des données chiffrées</strong> pour les premières années d'activité.</span>
                                        </p>
                                        <p className="flex gap-3 items-start">
                                            <span className="text-[#1D63FF] text-sm">●</span>
                                            <span>Il est aussi demandé par certains organismes pour obtenir des financements (matériel professionnel, informatique, stock de départ, trésorerie...).</span>
                                        </p>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    {/* Deuxième carte - même structure */}
                    <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-200">
                        <button 
                            onClick={toggleOpenRight}
                            className="w-full flex items-center justify-between group"
                        >
                            <h2 className="text-lg font-semibold text-gray-900 group-hover:text-[#1D63FF] transition-colors text-left pr-4">
                                Que contient votre budget prévisionnel ?
                            </h2>
                            <ChevronIcon isOpen={isOpenRight} />
                        </button>
                        
                        <AnimatePresence>
                            {isOpenRight && (
                                <motion.div 
                                    initial={{ height: 0, opacity: 0 }} 
                                    animate={{ height: 'auto', opacity: 1 }} 
                                    exit={{ height: 0, opacity: 0 }} 
                                    transition={{ duration: 0.2 }}
                                    className="overflow-hidden"
                                >
                                    <div className="mt-6 space-y-4 text-gray-600">
                                        <div className="flex gap-3 items-start">
                                            <span className="text-[#1D63FF] text-sm">●</span>
                                            <div>
                                                <strong className="text-gray-900">Une présentation détaillée de votre projet</strong>
                                                <p className="mt-1">Reprenant tous les éléments clés : activité, capital, chiffre d'affaires, investissements, etc.</p>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 items-start">
                                            <span className="text-[#1D63FF] text-sm">●</span>
                                            <div>
                                                <strong className="text-gray-900">Un tableau de financement</strong>
                                                <p className="mt-1">Détaillant les besoins de trésorerie et les ressources au lancement.</p>
                                            </div>
                                        </div>
                                        <div className="flex gap-3 items-start">
                                            <span className="text-[#1D63FF] text-sm">●</span>
                                            <div>
                                                <strong className="text-gray-900">Un compte de résultat à 3 ans</strong>
                                                <p className="mt-1">Récapitulatif des dépenses, recettes et résultats prévisionnels.</p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>

                {/* Section Comment obtenir */}
                <div className="mt-16 bg-white rounded-2xl shadow-sm p-8">
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-8">
                        <h2 className="text-2xl md:text-3xl font-bold text-gray-900">
                            Comment obtenir mon budget prévisionnel ?
                        </h2>
                        <img 
                            src="/img/illustration-bp.jpg" 
                            alt="Illustration budget" 
                            className="w-24 h-24 mt-4 md:mt-0 rounded-xl object-cover"
                        />
                    </div>

                    <p className="text-lg font-medium text-gray-700 mb-8">
                        Nous vous proposons deux formules:
                    </p>

                    <div className="space-y-6">
                        {/* Première formule */}
                        <div className="bg-gray-50 rounded-xl p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex gap-4">
                                <div className="flex-grow space-y-4">
                                    <div>
                                        <h3 className="text-xl font-semibold text-gray-900">
                                            Un budget Prévisionnel instantané
                                        </h3>
                                        <p className="text-gray-600 mt-2">
                                            Tiré directement de votre simulation réalisée en ligne, avec la forme juridique la plus adaptée à votre projet.
                                        </p>
                                        <div className="text-[#1D63FF] font-bold text-2xl mt-3">50€ HT</div>
                                    </div>
                                    <p className="text-gray-500 text-sm">
                                        (Il vous sera envoyé au format PDF sur votre boite mail dès le paiement réalisé).
                                    </p>
                                    <p className="text-gray-600">
                                        A savoir : notre budget prévisionnel issu de votre SIMULATION est très détaillé. Il tient compte de l'activité choisie, du niveau de chiffre d'affaires enregistré, de l'utilisation d'un véhicule pro ou perso, du niveau de rémunération, de l'impôts sur société avec les mises à jours fiscales…
                                    </p>
                                    <div className="flex justify-center mt-6">
                                        <ShimmerButton
                                            onClick={() => navigate('/Form')}
                                            className="bg-[#1D63FF] text-white py-3 px-6 rounded-lg font-medium hover:bg-[#1D63FF]/90 transition-colors"
                                        >
                                            Démarrer ma simulation
                                        </ShimmerButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Deuxième formule */}
                        <div className="bg-gray-50 rounded-xl p-6 hover:shadow-lg transition-all duration-200">
                            <div className="flex gap-4">
                                <div className="flex-grow space-y-4">
                                    <div>
                                        <h3 className="text-xl font-semibold text-gray-900">
                                            Un budget prévisionnel 100% personnalisé
                                        </h3>
                                        <p className="text-gray-600 mt-2">
                                            Comprend un RDV avec un expert en création
                                        </p>
                                        <div className="text-[#1D63FF] font-bold text-2xl mt-3">120€ HT</div>
                                    </div>
                                    <div className="text-gray-600 space-y-2">
                                        <p>
                                            Cette option est préconisée si vous avez des particularités personnelles, notamment la présence de l'aide au retour à l'emploi (ARE), une double activité, retraité…
                                        </p>
                                        <p>
                                            Cette option vous permet également d'y voir plus clair grâce à la possibilité de poser toutes vos questions à un professionnel en création, de comprendre pourquoi cette forme juridique est mieux qu'une autre, de pouvoir tout simplement discuter de votre projet.
                                        </p>
                                    </div>
                                    <div className="flex justify-center mt-6">
                                        <ShimmerButton
                                            onClick={() => window.open('https://calendly.com/fbsimulateurs/rendez-vous-conseils-budget-previsionnel', '_blank')}
                                            className="bg-[#1D63FF] text-white py-3 px-6 rounded-lg font-medium hover:bg-[#1D63FF]/90 transition-colors"
                                        >
                                            Prendre mon RDV
                                        </ShimmerButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
