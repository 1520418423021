import React from 'react';
import { Helmet } from 'react-helmet';
import { motion, Variants } from 'framer-motion';

const MentionsLegales = () => {
  const fadeIn: Variants = {
    initial: { 
      opacity: 0, 
      y: 20 
    },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  const containerVariants: Variants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-32 pb-16">
      <Helmet>
        <title>Mentions Légales - FBCONSEILS</title>
      </Helmet>
      
      <motion.div 
        className="max-w-4xl mx-auto px-4 mb-20"
        initial="initial"
        animate="animate"
        variants={containerVariants}
      >
        <motion.h1 
          variants={fadeIn}
          className="text-4xl font-bold text-gray-900 mb-12 text-center"
        >
          MENTIONS LÉGALES
        </motion.h1>
        
        <div className="space-y-8">
          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <p className="text-gray-700 leading-relaxed mb-6">
              Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, 
              il est précisé aux utilisateurs du site Maformejuridique.fr l'identité des différents intervenants dans le cadre 
              de sa réalisation et de son suivi.
            </p>
          </motion.section>

          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Edition du site</h2>
            <div className="pl-4 border-l-4 border-blue-100 space-y-4">
              <p className="text-gray-700">
                Le présent site, accessible à l'URL Https://maformejuridique.fr (le « Site »), est édité par :
              </p>
              <div className="space-y-2">
                <p className="text-gray-700">
                  Fb conseils, société au capital de 500 euros, inscrite au R.C.S. de ROMANS sous le numéro 931237192, 
                  dont le siège social est situé au 17 allée des chataigniers 26600 beaumont-monteux, 
                  représenté(e) par Fannie Rey dûment habilité(e)
                </p>
                <p className="text-gray-700">
                  Le numéro individuel TVA de l'éditeur est : Fr38931237192.
                </p>
              </div>
            </div>
          </motion.section>

          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Hébergement</h2>
            <div className="pl-4 border-l-4 border-blue-100">
              <p className="text-gray-700">
                Le Site est hébergé par la société Hostinger International LTD, situé, 
                (contact téléphonique ou email : https://www.hostinger.fr/contact).
              </p>
            </div>
          </motion.section>

          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Directeur de publication</h2>
            <div className="pl-4 border-l-4 border-blue-100">
              <p className="text-gray-700">
                Le Directeur de la publication du Site est Fannie Rey.
              </p>
            </div>
          </motion.section>

          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Nous contacter</h2>
            <div className="pl-4 border-l-4 border-blue-100 space-y-2">
              <p className="text-gray-700">Par téléphone : +33672589565</p>
              <p className="text-gray-700">Par email : fbsimulateurs@gmail.com</p>
              <p className="text-gray-700">Par courrier : 17 allée des chataigniers 26600 beaumont-monteux</p>
            </div>
          </motion.section>

          <motion.section 
            className="bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
            variants={fadeIn}
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Données personnelles</h2>
            <div className="pl-4 border-l-4 border-blue-100">
              <p className="text-gray-700">
                Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, 
                disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement 
                Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
              </p>
            </div>
          </motion.section>

          <motion.div 
            className="text-sm text-gray-500 text-center mt-8"
            variants={fadeIn}
          >
            Génération des mentions légales par Legalstart.
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default MentionsLegales; 