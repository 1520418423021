import DropDownJuridique from "../components/Card/DropDownJuridique";

const FormJuridic = () => {
  return (
    <div className="step-container bg-white">
      <div className="form-container">
        <div className="w-full max-w-4xl mx-auto">
          <DropDownJuridique />
        </div>
      </div>
    </div>
  );
};

export default FormJuridic;