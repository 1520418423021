import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ShimmerButton from '../components/magicui/shimmer-button';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@mui/material/CircularProgress';

const stripePromise = loadStripe('pk_live_51Pb5rcHnQLCbZW4Yq03X1C19tZqlBX4uVm0DO5GQ18lpOjYKhWQeo04xLoiwP4Zv91YUJacL9F1LEtelAcdj0MRm008TM7yz7B');
//const stripePromise = loadStripe('pk_test_51M5XfmHtRheYJmNr5Jtn8vwuNv6vqd7C3YpZyw9dOCFF7OOa1bBib3P126COAbYy6LtV9UGjmlmNo8CCTEjFCwuv00cdOSA8lZ');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2563eb',
    color: theme.palette.common.white,
    padding: '8px 4px',
    fontSize: '0.7rem',
    fontWeight: 600,
    borderBottom: 'none',
    minWidth: 'auto',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.65rem',
    padding: '6px 4px',
    color: '#374151',
    borderBottom: '1px solid #e5e7eb',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 'auto',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8fafc',
  },
  '&:nth-last-of-type(-n+2)': {
    backgroundColor: '#eff6ff',
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: '#f1f5f9',
    transition: 'background-color 0.2s ease',
  },
}));

interface CompanyType {
  salaryNetA: number;
  salaryNetB: number;
  salaryNetC: number;
  socialCotisationA: number;
  socialCotisationB: number;
  socialCotisationC: number;
  corporationTaxA: number;
  corporationTaxB: number;
  corporationTaxC: number;
  incomeTaxA: number;
  incomeTaxB: number;
  incomeTaxC: number;
  totalSocialSecurityContributionsA: number;
  totalSocialSecurityContributionsB: number;
  totalSocialSecurityContributionsC: number;
  cashBalanceA: number;
  cashBalanceB: number;
  cashBalanceC: number;
  additionalData1?: { C1: string; D1: string; };
  additionalData2?: { H1: string; I1: string; };
  additionalData3?: { B10: string; D10: string; };
  additionalData4?: { H10: string; I10: string; };
}

export interface DataType {
  bestStatus: string;
  ei: CompanyType;
  micro: CompanyType;
  sarl: CompanyType;
  sas: CompanyType;
}

export interface DataTableProps {
  data: DataType;
  userData: any; // Ajoutez cette ligne pour accepter les données utilisateur
}

const DataTable: React.FC<DataTableProps> = ({ data, userData }) => {
  const [, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const imagePaths = [
    "/img/BP a utiliser pour apercu-1.png",
    "/img/BP a utiliser pour apercu-2.png",
    "/img/BP a utiliser pour apercu-3.png",
    "/img/BP a utiliser pour apercu-4.png",
  ];

  const handlePayment = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, p0?: string) => {
    event.preventDefault();

    const stripe = await stripePromise;
    if (!stripe) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('https://fbconseil-c706cd2b1837.herokuapp.com/stripe/create-checkout-session', {
      //const response = await axios.post('http://localhost:3000/stripe/create-checkout-session', {

        data: userData,
        metadata: {
          name: userData.name,
          firstName: userData.firstName,
          email: userData.emailAddress,
        },
      });

      if (response.status !== 200) {
        console.error('Error creating checkout session:', response);
        return;
      }

      const session = response.data;
      const { id } = session; // Récupérez l'ID de la session

      const result = await stripe.redirectToCheckout({ sessionId: id });

      if (result.error) {
        console.error('Error redirecting to Stripe:', result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setErrorMessage('Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handlePayment1 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open('https://calendly.com/fbsimulateurs/30min', '_blank');
  };

  const handlePayment2 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open('https://calendly.com/fbsimulateurs/rendez-vous-conseils-budget-previsionnel', '_blank');
  };


  const formatNumber = (num: number | null | undefined) => {
    if (num === null || num === undefined) {
      return '-'; // Ou une autre valeur par dfaut, comme une chaîne vide ou 0
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  
  
  
  
  return (
    <div className="space-y-8 p-4 w-full max-w-[1800px] mx-auto">
      <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Mon meilleur statut : {data.bestStatus || "Statut non disponible"}
        </h1>
        <div className="text-xl text-gray-600 mb-8">
          ⚠️ Cette simulation ne tient pas compte des particularités de la situation personnelle (ARE, activité simultanée, ...).
          <br/>
          Contactez-nous pour une simulation personnalisée de votre projet.
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex-1 space-y-4">
            <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
              <div className="flex-1 mr-3">
                <div className="flex justify-between items-start">
                  <span className="text-sm text-gray-700 truncate">🔵 J'obtiens mon Budget Prévisionnel issu de la simulation</span>
                  <ShimmerButton
                    className="!py-1.5 !px-4 !text-sm !min-h-0 !min-w-0 ml-4"
                    data-amount="5000"
                    onClick={handlePayment}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={16} color="inherit" /> : 'Payer'}
                  </ShimmerButton>
                </div>
                <div className="text-sm font-semibold text-blue-600 mt-1">50€ HT</div>
              </div>
            </div>

            <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
              <div className="flex-1 mr-3">
                <div className="flex justify-between items-start">
                  <span className="text-sm text-gray-700 truncate">🔵 J'obtiens un Rendez-vous conseil avec un professionnel</span>
                  <ShimmerButton
                    className="!py-1.5 !px-4 !text-sm !min-h-0 !min-w-0 ml-4"
                    onClick={handlePayment1}
                  >
                    Payer
                  </ShimmerButton>
                </div>
                <div className="text-sm font-semibold text-blue-600 mt-1">80€ HT</div>
              </div>
            </div>

            <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
              <div className="flex-1 mr-3">
                <div className="flex justify-between items-start">
                  <span className="text-sm text-gray-700 truncate">🔵 Budget Prévisionnel personnalisé + Rendez-vous conseil</span>
                  <ShimmerButton
                    className="!py-1.5 !px-4 !text-sm !min-h-0 !min-w-0 ml-4"
                    data-amount="10000"
                    onClick={handlePayment2}
                  >
                    Payer
                  </ShimmerButton>
                </div>
                <div className="text-sm font-semibold text-blue-600 mt-1">120€ HT</div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3">
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-sm font-semibold text-gray-700 mb-3">Aperçu de votre budget prévisionnel:</h3>
              <div className="space-y-2 max-h-[300px] overflow-y-auto">
                {imagePaths.map((path, index) => (
                  <img
                    key={index}
                    src={path}
                    alt={`Page ${index + 1}`}
                    className="w-full rounded-md shadow-sm hover:shadow-md transition-shadow"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {["ei", "sarl", "micro", "sas"].map((key) => {
          const companyData = data[key as keyof DataType];
          const type = key.toUpperCase();
  
          if (typeof companyData === 'string') {
            return null; // Skip if companyData is not of type CompanyType
          }
  
          let salaireMessage = "SALAIRE NET";
          let cotisationsMessage = "";
          let impotsSocieteMessage = "IMPOT SUR LES SOCIETES";
          let impotsRevenuMessage = "IMPOT SUR LE REVENU";
          let totalChargeSocialeMessage = "TOTAL CHARGES SOCIALES ET IMPOT SUR LE REVENU";
          let soldeTresorerie = "SOLDE DE TRESORERIE";
  
          if (type === "EI" || type === "MICRO") {
            cotisationsMessage = "COTISATIONS SOCIALES";
          }
  
          if (type === "SARL") {
            cotisationsMessage = "COTISATIONS SOCIALES TNS SARL";
          }
  
          if (type === "SAS") {
            cotisationsMessage = "COTISATIONS SOCIALES PRESIDENT SAS";
          }
  
          return (
            <Card
              key={key}
              sx={{
                background: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                overflow: 'hidden',
                width: '100%',
                '&:hover': {
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              <CardContent sx={{ padding: '16px' }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: '8px',
                    boxShadow: 'none',
                    border: '1px solid #e5e7eb',
                    width: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  <Table
                    size="small"
                    sx={{
                      width: '100%',
                      tableLayout: 'fixed',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell 
                          align="center" 
                          sx={{ 
                            width: '40%',
                          }}
                        >
                          {type}
                        </StyledTableCell>
                        {type !== "MICRO" && (
                          <StyledTableCell 
                            align="center"
                            sx={{ width: '20%' }}
                          >
                            N
                          </StyledTableCell>
                        )}
                        {type === "MICRO" ? (
                          <StyledTableCell 
                            align="center" 
                            colSpan={3}
                            sx={{ width: '60%' }}
                          >
                            {companyData.additionalData3?.B10 || ''}
                          </StyledTableCell>
                        ) : (
                          <>
                            <StyledTableCell 
                              align="center"
                              sx={{ width: '20%' }}
                            >
                              {companyData.additionalData1?.C1 || companyData.additionalData2?.H1 || companyData.additionalData4?.H10 || 'N+1'}
                            </StyledTableCell>
                            <StyledTableCell 
                              align="center"
                              sx={{ width: '20%' }}
                            >
                              {companyData.additionalData1?.D1 || companyData.additionalData2?.I1 || companyData.additionalData4?.I10 || 'N+2'}
                            </StyledTableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {salaireMessage}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.salaryNetA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.salaryNetB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.salaryNetC)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {cotisationsMessage}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.socialCotisationA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.socialCotisationB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.socialCotisationC)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {impotsSocieteMessage}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.corporationTaxA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.corporationTaxB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.corporationTaxC)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {impotsRevenuMessage}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.incomeTaxA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.incomeTaxB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.incomeTaxC)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {totalChargeSocialeMessage}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.totalSocialSecurityContributionsA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.totalSocialSecurityContributionsB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.totalSocialSecurityContributionsC)}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {soldeTresorerie}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.cashBalanceA)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.cashBalanceB)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatNumber(companyData.cashBalanceC)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );
  
};

export default DataTable;