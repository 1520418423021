import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import RGPDContent from './RGPDContent';

const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSimulationClick = () => {
    setTimeout(() => {
      navigate('/Form'); 
    }, 1000);
  };

  const handleLegalClick = () => {
    navigate('/mentions-legales');
  };

  const handleCGVClick = () => {
    setSelectedPdf('/documents/CGV.pdf');
  };

  const handleCGUClick = () => {
    setSelectedPdf('/documents/CGU.pdf');
  };

  return (
    <>
      <div className="relative py-6 footer-container mb-4">
        <hr className="border-gray-400 w-[50vw] mx-auto" />
        <div className="mt-6 flex justify-center space-x-12">
          <Link className="text-xl font-bold text-gray-700 footer-text hover:underline" to="/">
            Accueil
          </Link>
          <button 
            className="text-xl font-bold text-gray-700 footer-text hover:underline"
            onClick={handleSimulationClick}
          >
            Simulation
          </button>
          <Link className="text-xl font-bold text-gray-700 footer-text hover:underline" to="/Contact">
            Contact
          </Link>
        </div>

        <h1 onClick={() => setShowModal(true)} className="text-center mt-4 cursor-pointer hover:text-gray-500">
          PROTECTION DES DONNEES
        </h1>

        <footer className="fixed bottom-0 left-0 right-0 bg-white/90 backdrop-blur-sm border-t border-gray-100 z-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col sm:flex-row justify-between items-center py-4 space-y-2 sm:space-y-0">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">© 2024</span>
                <span className="text-sm font-medium text-gray-700">FBCONSEILS - SIRET 931 237 192</span>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleLegalClick}
                  className="text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200 px-3 py-2 rounded-md hover:bg-gray-50"
                >
                  Mentions Légales
                </button>
                <button
                  onClick={handleCGVClick}
                  className="text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200 px-3 py-2 rounded-md hover:bg-gray-50"
                >
                  CGV
                </button>
                <button
                  onClick={handleCGUClick}
                  className="text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200 px-3 py-2 rounded-md hover:bg-gray-50"
                >
                  CGU
                </button>
              </div>
            </div>
          </div>
        </footer>
      </div>

      {/* Modal RGPD */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-center bg-gray-200 rounded py-4" id="modal-title">
                      PROTECTION DES DONNÉES
                    </h3>
                    <div className="mt-2">
                      <RGPDContent />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#1D63FF] text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowModal(false)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PDF Viewer */}
      {selectedPdf && (
        <PDFViewer 
          pdfUrl={selectedPdf} 
          onClose={() => setSelectedPdf(null)} 
        />
      )}
    </>
  );
};

export default Footer;
