import React, { useState, useEffect } from 'react';
import { AnnualRevenueSlider } from '../components/annualRevenueSlider';
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ShimmerButton from '../components/magicui/shimmer-button';
import '../styles/loader.css'
import { Helmet } from 'react-helmet';
import { BusinessData, SelectedRevenue } from '../Types/types';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { DataType } from '../components/DataTable';
import Button from '@mui/material/Button';
import InputsVehicleVP from '../components/InputsVehicleVP';
import InputsVehicleVU from '../components/InputsVehicleVU';
import { Arrow } from '../components/arrow';
//import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Select, { MultiValue } from 'react-select'


ReactGA.initialize('G-78TF44GTX6');
ReactGA.send({ hitType: 'pageview', page: 'window.location.pathname' });


const Forms = () => {



    <Helmet>
        <title>Simulateur de forme juridique - Trouvez la meilleure structure pour votre entreprise | FBConseils</title>
        <meta name="description" content="Utilisez notre simulateur de forme juridique pour déterminer la meilleure structure pour votre entreprise. Répondez à quelques questions simples et nous vous fournirons une recommandation basée sur vos réponses." />
    </Helmet>
    const [loading, setLoading] = useState(false);
    const [investissement, setInvestissement] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [, setNumEmployees] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [, setNumVehicles] = useState(0);
    const [, setNumUtilityVehicles] = useState(0);
    const [showInvestissementMaterielsInput, setShowInvestissementMaterielsInput] = useState(false);
    const [showPersonalVehicleFields, setShowPersonalVehicleFields] = useState(false);
    const [showLoanFields, setShowLoanFields] = useState(false);
    //const [showOtherIncomeField, setShowOtherIncomeField] = useState(false);
    const [tempTaux, setTempTaux] = useState("");



    //multi select 

    const customStyles = {
        control: (baseStyles: any, state: { isFocused: any; }) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            borderColor: '#273c75',
            border: '2px solid',
            padding: '12px'
        }),
        multiValue: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
        }),
        multiValueLabel: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: '#f4f4f4 ',
            fontWeight: '550',
            border: '1px solid #273c75',
            borderRadius: '8px',
            padding: '8px'
        }),
        multiValueRemove: (baseStyles: any) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            ':hover': {
                backgroundColor: 'transparent',
                color: '#273c75',
            },
        }),
        menuList: (baseStyles: any) => ({
            ...baseStyles,
            maxHeight: '200px', // Augmentez cette valeur pour permettre plus de défilement
            overflowY: 'auto',
        }),
    };



    const removeAccents = (str: string) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleRevenueSelect = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
        const updatedRevenues = selectedOptions.map(option => {
            const normalizedLabel = removeAccents(option.label);
            const existingRevenue = selectedRevenues.find(revenue => removeAccents(revenue.type) === normalizedLabel);
            return existingRevenue || { type: option.label, amount: '' };
        });
        setSelectedRevenues(updatedRevenues);
    };

    const handleRevenueAmountChange = (index: number, amount: string) => {
        const updatedRevenues = [...selectedRevenues];
        updatedRevenues[index].amount = amount;
        setSelectedRevenues(updatedRevenues);
    };

    const colourOptions = [
        { value: 'revenusFonciers', label: 'Revenus fonciers pas cumulable avec micro foncier' },
        { value: 'revenusMicroFoncier', label: 'Revenus micro foncier max 15 000€' },
        { value: 'revenusCapitauxMobilierFlatTax', label: 'Revenus capitaux mobilier flat tax' },
        { value: 'revenusCapitauxMobilierBaremeProgressif', label: 'Revenus capitaux mobilier option barème progressif' },
        { value: 'revenuBIC', label: 'Revenu BIC' },
        { value: 'revenuMicroBICVentes', label: 'Revenu micro BIC ventes de marchandises' },
        { value: 'revenuMicroBICServices', label: 'Revenu micro BIC prestations de services' },
        { value: 'revenuBNC', label: 'Revenu BNC' },
        { value: 'revenuMicroBNC', label: 'Revenu micro BNC' }
    ]



    //const navigate = useNavigate();
    const { scrollYProgress } = useViewportScroll();
    const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [userName, setUserName] = useState<string>('');
    const [userFirstName, setUserFirstName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [userCompany,] = useState<string>('');
    const [userPriceOfLocationPro,] = useState<number>(0);
    const [userBenefice, setUserBenefice] = useState<string>('');
    const [userActivity, setUserActivity] = useState<string>('');
    const [userAnnualTurnover, setUserAnnualTurnover] = useState<number>(0);
    const [userPriceOfAnnualCommercialLocal, setUserPriceOfAnnualCommercialLocal] = useState<number>(0);
    const [userAmountOfTheStartingStock, setUserAmountOfTheStartingStock] = useState<number>(0);
    const [userContainEmployee, setUserContainEmployee] = useState<string>('');
    const [userNumberOfEmployee, setUserNumberOfEmployee] = useState<number>(0);
    const [userGrossMonthlySalary1, setUserGrossMonthlySalary1] = useState<number>(0);
    const [userGrossMonthlySalary2, setUserGrossMonthlySalary2] = useState<number>(0);
    const [userGrossMonthlySalary3, setUserGrossMonthlySalary3] = useState<number>(0);
    const [userNetMonthlyRemuneration, setUserNetMonthlyRemuneration] = useState<number>(0);
    const [userMaterialInvestment, setUserMaterialInvestment] = useState<string>('');
    const [userNumberMaterialInvestment, setUserNumberMaterialInvestment] = useState<number>(0);
    const [userVehicleInvestment, setUserVehicleInvestment] = useState<string>('');
    const [userNumberVehicleVP, setUserNumberVehicleVP] = useState<number>(0);
    const [userNumberVehicleVU, setUserNumberVehicleVU] = useState<number>(0);
    const [userPriceVP1, setUserPriceVP1] = useState<number>(0);
    const [userCO2VP1, setUserCO2VP1] = useState<number>(0);
    const [userDateRegistrationVP1, setUserDateRegistrationVP1] = useState<number>(0);
    const [userPriceVP2, setUserPriceVP2] = useState<number>(0);
    const [userCO2VP2, setUserCO2VP2] = useState<number>(0);
    const [userDateRegistrationVP2, setUserDateRegistrationVP2] = useState<number>(0);
    const [userPriceVP3, setUserPriceVP3] = useState<number>(0);
    const [userCO2VP3, setUserCO2VP3] = useState<number>(0);
    const [userDateRegistrationVP3, setUserDateRegistrationVP3] = useState<number>(0);
    const [userPriceVU1, setUserPriceVU1] = useState<number>(0);
    const [userPriceVU2, setUserPriceVU2] = useState<number>(0);
    const [userPriceVU3, setUserPriceVU3] = useState<number>(0);
    const [userProposedShareCapital, setUserProposedShareCapital] = useState<number>(0);
    const [userPersonalContributionOfMoney, setUserPersonalContributionOfMoney] = useState<number>(0);
    const [userLoan, setUserLoan] = useState<string>('');
    const [userLoanAmount, setUserLoanAmount] = useState<number>(0);
    const [userLoanTaux, setUserLoanTaux] = useState<number>(0);
    const [userLoanNumberOfYear, setUserLoanNumberOfYear] = useState<number>(1);
    const [userUsePersonalVehicle, setUserUsePersonalVehicle] = useState<string>('');
    const [userNumberOfTaxHorses, setUserNumberOfTaxHorses] = useState<number>(0);
    const [userNumberOfAnnualProfessionalKM, setUserNumberOfAnnualProfessionalKM] = useState<number>(0);
    const [userNumberOfFiscalPart, setUserNumberOfFiscalPart] = useState<number | null>(1);
    const [userOtherAnnualSalariedIncome,] = useState<number>(0);
    const [userOtherSalariedIncomeOfYourHousehold, setUserOtherSalariedIncomeOfYourHousehold] = useState<number>(0);
    const [userOtherSalariedAnnual,] = useState<number>(0);
    const [selectedRevenues, setSelectedRevenues] = useState<SelectedRevenue[]>([]);



    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const isMobile = width < 768;
            setIsMobile(isMobile);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [userData, setUserData] = useState<BusinessData | null>(null);
    const [responseData, setResponseData] = useState<DataType | null>(null);


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (userFirstName === "" && userName === "") {
            setErrorMessage("Le prénom et le nom sont obligatoires");
            return
        } else if (userAnnualTurnover === 0) {
            setErrorMessage("Le chiffre d'affaire est obligatoire");
            return
        } else if (userActivity === "") {
            setErrorMessage("L'activité est obligatoire");
            return
        } if (userContainEmployee === "") {
            setErrorMessage("La présence de salarié est obligatoire");
            return
        } else if (userMaterialInvestment === "") {
            setErrorMessage("L'investissement en matériel est obligatoire");
            return
        } else if (userVehicleInvestment === "") {
            setErrorMessage("L'investissement véhicule est obligatoire");
            return
        } else if (userLoan === "") {
            setErrorMessage("L'emprunt est obligatoire");
            return
        } else if (userLoanAmount === 0 && userLoan === "OUI") {
            setErrorMessage("Le montant de l'emprunt est obligatoire");
            return
        } else if (userUsePersonalVehicle === "") {
            setErrorMessage("L'utilisation du véhicule personnel est obligatoire");
            return
        } else if (userUsePersonalVehicle === "OUI" && userNumberOfTaxHorses === 0) {
            setErrorMessage("Le nombre de chevaux fiscaux est obligatoire");
            return;
        } else if (userUsePersonalVehicle === "OUI" && userNumberOfAnnualProfessionalKM === 0) {
            setErrorMessage("Le nombre de kilomètres professionnels est obligatoire");
            return;
        } else {
            setErrorMessage("");
            setLoading(true);
        }

        const finalNumberOfFiscalPart = Math.max(1, userNumberOfFiscalPart || 1);
        const apiUrl = "https://fbconseil-c706cd2b1837.herokuapp.com/api/simulation";
        // const apiUrl = "http://localhost:3000/api/simulation";

        if (!apiUrl) {
            throw new Error('La variable d\'environnement REACT_APP_API_URL n\'est pas définie');
        }
        try {
            const data: BusinessData = {
                // ... les données utilisateur

                name: userName,
                firstName: userFirstName,
                emailAddress: userEmail,
                companyName: userCompany,
                activity: userActivity,
                annualTurnover: userAnnualTurnover,
                priceOfAnnualCommercialLocal: userPriceOfAnnualCommercialLocal,
                amountOfTheStartingStock: userAmountOfTheStartingStock,
                priceOfTheLocationProject: userPriceOfLocationPro,
                containEmployee: userContainEmployee,
                numberOfEmployee: userNumberOfEmployee,
                grossMonthlySalary1: userGrossMonthlySalary1,
                grossMonthlySalary2: userGrossMonthlySalary2,
                grossMonthlySalary3: userGrossMonthlySalary3,
                netMonthlyRemuneration: userNetMonthlyRemuneration,
                materialInvestment: userMaterialInvestment,
                numberMaterialInvestment: userNumberMaterialInvestment,
                vehicleInvestment: userVehicleInvestment,
                numberVehicleVP: userNumberVehicleVP,
                numberVehicleVU: userNumberVehicleVU,
                priceVP1: userPriceVP1,
                cO2VP1: userCO2VP1,
                dateRegistrationVP1: userDateRegistrationVP1,
                priceVP2: userPriceVP2,
                cO2VP2: userCO2VP2,
                dateRegistrationVP2: userDateRegistrationVP2,
                priceVP3: userPriceVP3,
                cO2VP3: userCO2VP3,
                dateRegistrationVP3: userDateRegistrationVP3,
                priceVU1: userPriceVU1,
                priceVU2: userPriceVU2,
                priceVU3: userPriceVU3,
                proposedShareCapital: userProposedShareCapital,
                personalContributionOfMoney: userPersonalContributionOfMoney,
                loan: userLoan,
                loanAmount: userLoanAmount,
                loanTaux: userLoanTaux / 100,
                loanNumberOfYear: userLoanNumberOfYear,
                usePersonalVehicle: userUsePersonalVehicle,
                numberOfTaxHorses: userNumberOfTaxHorses,
                numberOfAnnualProfessionalKM: userNumberOfAnnualProfessionalKM,
                numberOfFiscalPart: finalNumberOfFiscalPart,
                otherAnnualSalariedIncome: userOtherAnnualSalariedIncome,
                otherSalariedIncomeOfYourHousehold: userOtherSalariedIncomeOfYourHousehold,
                otherSalariedAnnual: userOtherSalariedAnnual,
                benefice: userBenefice,
                selectedRevenues: selectedRevenues
            };

            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            setResponseData(response.data.data);
            setUserData(data); // Stocker les données utilisateur ici
            setLoading(false);
            handleOpen();
        } catch (error) {
            setErrorMessage("Une erreur est survenue");
        } finally {
            setLoading(false);
        }
    };


    function generateInputsVehicleVP(index: number) {
        switch (index) {
            case 0:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP1} setValue1={setUserPriceVP1} value2={userCO2VP1} setValue2={setUserCO2VP1} value3={userDateRegistrationVP1} setValue3={setUserDateRegistrationVP1} />;
            case 1:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP2} setValue1={setUserPriceVP2} value2={userCO2VP2} setValue2={setUserCO2VP2} value3={userDateRegistrationVP2} setValue3={setUserDateRegistrationVP2} />;
            case 2:
                return <InputsVehicleVP key={index} index={index} value1={userPriceVP3} setValue1={setUserPriceVP3} value2={userCO2VP3} setValue2={setUserCO2VP3} value3={userDateRegistrationVP3} setValue3={setUserDateRegistrationVP3} />;
            default:
                return null;
        }
    }


    function generateInputsVehicleVU(index: number) {
        switch (index) {
            case 0:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU1} setValue={setUserPriceVU1} />;
            case 1:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU2} setValue={setUserPriceVU2} />;
            case 2:
                return <InputsVehicleVU key={index} index={index} value={userPriceVU3} setValue={setUserPriceVU3} />;
            default:
                return null;
        }
    }

    const setSalary = (num: number, value: number) => {
        switch (num) {
            case 1:
                setUserGrossMonthlySalary1(value);
                break;
            case 2:
                setUserGrossMonthlySalary2(value);
                break;
            case 3:
                setUserGrossMonthlySalary3(value);
                break;
            default:
                break;
        }
    }

    // Styles communs
    const sectionClasses = "bg-white rounded-xl p-6 shadow-sm border border-gray-100 hover:shadow-md transition-all duration-300 mb-8";
    const inputClasses = "w-full px-4 py-3 rounded-lg border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-white text-gray-700";
    const labelClasses = "block text-sm font-medium text-gray-700 mb-2";
    const selectClasses = `${inputClasses} cursor-pointer`;

    return (
        <>
            <Arrow />
            <Helmet>
                <title>Simulateur de forme juridique - Trouvez la meilleure structure pour votre entreprise | FBConseils</title>
                <meta name="description" content="Utilisez notre simulateur de forme juridique pour déterminer la meilleure structure pour votre entreprise. Répondez à quelques questions simples et nous vous fournirons une recommandation basée sur vos réponses." />
            </Helmet>

            <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                {isMobile && <motion.div className="progress-bar" style={{ scaleX, originX: 0, scaleY: 2, backgroundColor: "#3f9bf1" }} />}
                
                <div className="max-w-4xl mx-auto">
                    <form className="space-y-8 mt-20">
                        {/* En-tête */}
                        <div className={sectionClasses}>
                            <h1 className="text-2xl font-bold text-center text-gray-900 mb-4">
                                Votre Simulation
                            </h1>
                            <div className="h-1 w-20 bg-blue-500 mx-auto rounded-full mb-6"></div>
                        </div>

                        {/* Section Informations Personnelles */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">1</span>
                                Informations Personnelles
                            </h2>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="name" className={labelClasses}>
                                        Nom<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className={inputClasses}
                                        placeholder="Votre nom"
                                        onChange={(e) => {
                                            if (/^[A-Za-z]*$/.test(e.target.value)) {
                                                setUserName(e.target.value.toUpperCase());
                                            }
                                        }}
                                        value={userName}
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="firstName" className={labelClasses}>
                                        Prénom<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        className={inputClasses}
                                        placeholder="Votre prénom"
                                        onChange={(e) => {
                                            if (/^[A-Za-z]*$/.test(e.target.value)) {
                                                setUserFirstName(e.target.value.toUpperCase());
                                            }
                                        }}
                                        value={userFirstName}
                                        required
                                    />
                                </div>

                                <div className="md:col-span-2">
                                    <label htmlFor="email" className={labelClasses}>
                                        Email<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className={inputClasses}
                                        placeholder="votre@email.com"
                                        onChange={(e) => setUserEmail(e.target.value)}
                                        value={userEmail}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Section Activité */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">2</span>
                                Activité
                            </h2>

                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="activity" className={labelClasses}>
                                        Type d'activité<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="activity"
                                        className={selectClasses}
                                        onChange={(e) => setUserActivity(e.target.value)}
                                        value={userActivity}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="ARTISAN">ARTISAN</option>
                                        <option value="COMMERCANT">COMMERCANT</option>
                                        <option value="CONSULTANT">CONSULTANT</option>
                                        <option value="LIBERAL">LIBERAL</option>
                                    </select>
                                </div>

                                {userActivity === "COMMERCANT" && (
                                    <div className="space-y-6 animate-fadeIn">
                                        <div>
                                            <label htmlFor="annualCommercialLocal" className={labelClasses}>
                                                Loyer annuel du local commercial
                                            </label>
                                            <input
                                                type="number"
                                                id="annualCommercialLocal"
                                                className={inputClasses}
                                                placeholder="Montant annuel"
                                                onChange={(e) => {
                                                    if (/^[0-9]*$/.test(e.target.value)) {
                                                        setUserPriceOfAnnualCommercialLocal(Number(e.target.value));
                                                    }
                                                }}
                                                value={userPriceOfAnnualCommercialLocal}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="startingStock" className={labelClasses}>
                                                Montant du stock de départ
                                            </label>
                                            <input
                                                type="number"
                                                id="startingStock"
                                                className={inputClasses}
                                                placeholder="Montant"
                                                onChange={(e) => {
                                                    if (/^[0-9]*$/.test(e.target.value)) {
                                                        setUserAmountOfTheStartingStock(Number(e.target.value));
                                                    }
                                                }}
                                                value={userAmountOfTheStartingStock}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <label className={labelClasses}>
                                        Chiffre d'affaires annuel<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <div className="bg-white rounded-lg p-6 border border-gray-200">
                                        <AnnualRevenueSlider
                                            onChange={(value: number | string) => setUserAnnualTurnover(Number(value))}
                                            value={userAnnualTurnover}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Section Employés */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">3</span>
                               Charges de personnels
                            </h2>

                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="containEmployee" className={labelClasses}>
                                        Présence de salarié<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="containEmployee"
                                        className={selectClasses}
                                        onChange={(e) => {
                                            if (e.target.value === 'OUI') {
                                                setUserContainEmployee("OUI")
                                                setShowInputs(true);
                                            } else {
                                                setUserContainEmployee("NON")
                                                setShowInputs(false);
                                                setUserNumberOfEmployee(0);
                                                setUserGrossMonthlySalary1(0);
                                                setUserGrossMonthlySalary2(0);
                                                setUserGrossMonthlySalary3(0);
                                            }
                                        }}
                                        value={userContainEmployee}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>

                                {showInputs && (
                                    <div className="space-y-6 animate-fadeIn">
                                        <div>
                                            <label htmlFor="numEmployees" className={labelClasses}>
                                                Nombre de salariés (max 3)
                                            </label>
                                            <input
                                                type="number"
                                                id="numEmployees"
                                                className={inputClasses}
                                                placeholder="Nombre de salariés"
                                                min="1"
                                                max="3"
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        const numEmployees = Number(e.target.value);
                                                        if (numEmployees <= 3) {
                                                            setUserNumberOfEmployee(numEmployees);
                                                            setNumEmployees(numEmployees);
                                                        }
                                                    }
                                                }}
                                                value={userNumberOfEmployee}
                                            />
                                        </div>

                                        {Array.from({ length: userNumberOfEmployee }, (_, index) => (
                                            <div key={`employee${index + 1}`} className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                                <label htmlFor={`employee${index + 1}`} className={labelClasses}>
                                                    Salaire brut mensuel - Salarié {index + 1}
                                                </label>
                                                <input
                                                    type="number"
                                                    id={`employee${index + 1}`}
                                                    className={inputClasses}
                                                    placeholder={`Salaire brut mensuel - Salarié ${index + 1}`}
                                                    onChange={(e) => {
                                                        if (/^\d*$/.test(e.target.value)) {
                                                            const value = e.target.value ? parseInt(e.target.value, 10) : 0;
                                                            setSalary(index + 1, value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div>
                                    <label htmlFor="netMonthlyRemuneration" className={labelClasses}>
                                        Votre rémunération nette mensuelle<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="netMonthlyRemuneration"
                                        className={inputClasses}
                                        placeholder="Votre rémunération nette mensuelle"
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value)) {
                                                setUserNetMonthlyRemuneration(Number(e.target.value))
                                            }
                                        }}
                                        value={userNetMonthlyRemuneration}
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="benefice" className={labelClasses}>
                                        Souhaitez-vous prendre tout le bénéfice ?<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="benefice"
                                        className={selectClasses}
                                        onChange={(e) => setUserBenefice(e.target.value)}
                                        value={userBenefice}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Section Investissements */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">4</span>
                                Investissements
                            </h2>

                            <div className="space-y-6">
                                {/* Investissement Matériels */}
                                <div>
                                    <label htmlFor="investment-materials" className={labelClasses}>
                                       Matériels<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="investment-materials"
                                        className={selectClasses}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === 'OUI') {
                                                setShowInvestissementMaterielsInput(true);
                                                setUserMaterialInvestment("OUI")
                                            } else {
                                                setShowInvestissementMaterielsInput(false);
                                                setUserMaterialInvestment("NON")
                                                setUserNumberMaterialInvestment(0);
                                            }
                                        }}
                                        value={userMaterialInvestment}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>

                                {showInvestissementMaterielsInput && (
                                    <div className="space-y-4 animate-fadeIn p-6 bg-gray-50 rounded-lg border border-gray-200">
                                        <label htmlFor="materialAmount" className={labelClasses}>
                                            Montant des investissements matériels<span className="text-red-500 ml-1">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            id="materialAmount"
                                            className={inputClasses}
                                            placeholder="Montant en euros"
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    setUserNumberMaterialInvestment(Number(e.target.value))
                                                }
                                            }}
                                            value={userNumberMaterialInvestment}
                                            required
                                        />
                                    </div>
                                )}

                                {/* Investissement Véhicules */}
                                <div className="mt-8">
                                    <label htmlFor="invertissement-vehicule" className={labelClasses}>
                                        Véhicules<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="invertissement-vehicule"
                                        className={selectClasses}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setInvestissement(value);
                                            setUserVehicleInvestment(value);
                                            if (value === 'NON') {
                                                setUserNumberVehicleVP(0);
                                                setUserNumberVehicleVU(0);
                                                setUserPriceVP1(0);
                                                setUserCO2VP1(0);
                                                setUserDateRegistrationVP1(0);
                                                setUserPriceVP2(0);
                                                setUserCO2VP2(0);
                                                setUserDateRegistrationVP2(0);
                                                setUserPriceVP3(0);
                                                setUserCO2VP3(0);
                                                setUserDateRegistrationVP3(0);
                                                setUserPriceVU1(0);
                                                setUserPriceVU2(0);
                                                setUserPriceVU3(0);
                                            }
                                        }}
                                        value={userVehicleInvestment}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>

                                {investissement === 'OUI' && (
                                    <div className="space-y-6 animate-fadeIn">
                                        {/* Véhicules de tourisme */}
                                        <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                            <label htmlFor="numVehicleVP" className={labelClasses}>
                                                Nombre de véhicules de tourisme
                                            </label>
                                            <input
                                                type="number"
                                                id="numVehicleVP"
                                                className={inputClasses}
                                                placeholder="Nombre de véhicules"
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        setNumVehicles(parseInt(e.target.value));
                                                        setUserNumberVehicleVP(Number(e.target.value))
                                                    }
                                                }}
                                                value={userNumberVehicleVP}
                                                min="0"
                                                max="3"
                                            />

                                            {/* Champs dynamiques pour les véhicules VP */}
                                            <div className="mt-4 space-y-4">
                                                {[...Array(userNumberVehicleVP)].map((_, index) => (
                                                    <div key={`vp-${index}`} className="p-4 bg-white rounded-lg border border-gray-200">
                                                        {generateInputsVehicleVP(index)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Véhicules utilitaires */}
                                        <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                            <label htmlFor="numVehicleVU" className={labelClasses}>
                                                Nombre de véhicules utilitaires
                                            </label>
                                            <input
                                                type="number"
                                                id="numVehicleVU"
                                                className={inputClasses}
                                                placeholder="Nombre de véhicules"
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        setNumUtilityVehicles(parseInt(e.target.value));
                                                        setUserNumberVehicleVU(Number(e.target.value))
                                                    }
                                                }}
                                                value={userNumberVehicleVU}
                                                min="0"
                                                max="3"
                                            />

                                            {/* Champs dynamiques pour les véhicules VU */}
                                            <div className="mt-4 space-y-4">
                                                {[...Array(userNumberVehicleVU)].map((_, index) => (
                                                    <div key={`vu-${index}`} className="p-4 bg-white rounded-lg border border-gray-200">
                                                        {generateInputsVehicleVU(index)}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Section Financière */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">5</span>
                                Informations Financières
                            </h2>

                            <div className="space-y-6">
                                {/* Capital social */}
                                <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                    <label htmlFor="proposedShareCapital" className={labelClasses}>
                                        Capital social envisagé<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="proposedShareCapital"
                                        className={inputClasses}
                                        placeholder="Montant du capital social"
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value)) {
                                                setUserProposedShareCapital(Number(e.target.value))
                                            }
                                        }}
                                        value={userProposedShareCapital}
                                        required
                                    />
                                </div>

                                {/* Apport personnel */}
                                <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                    <label htmlFor="personalContribution" className={labelClasses}>
                                        Apport d'argent personnel<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        id="personalContribution"
                                        className={inputClasses}
                                        placeholder="Montant de l'apport personnel"
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value)) {
                                                setUserPersonalContributionOfMoney(Number(e.target.value))
                                            }
                                        }}
                                        value={userPersonalContributionOfMoney}
                                        required
                                    />
                                </div>

                                {/* Emprunt */}
                                <div className="space-y-6">
                                    <label htmlFor="loan" className={labelClasses}>
                                        Emprunt<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="loan"
                                        className={selectClasses}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === 'OUI') {
                                                setShowLoanFields(true);
                                                setUserLoan("OUI")
                                            } else {
                                                setShowLoanFields(false);
                                                setUserLoan("NON")
                                                setUserLoanAmount(0);
                                                setUserLoanTaux(0);
                                                setTempTaux('');
                                                setUserLoanNumberOfYear(1);
                                            }
                                        }}
                                        value={userLoan}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>

                                    {showLoanFields && (
                                        <div className="space-y-6 animate-fadeIn bg-white p-6 rounded-lg border border-gray-200">
                                            <div>
                                                <label htmlFor="loanAmount" className={labelClasses}>
                                                    Montant de l'emprunt
                                                </label>
                                                <input
                                                    type="number"
                                                    id="loanAmount"
                                                    className={inputClasses}
                                                    placeholder="Montant"
                                                    onChange={(e) => {
                                                        if (/^\d*$/.test(e.target.value)) {
                                                            setUserLoanAmount(Number(e.target.value))
                                                        }
                                                    }}
                                                    value={userLoanAmount}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="loanRate" className={labelClasses}>
                                                    Taux d'intérêt (%)
                                                </label>
                                                <input
                                                    type="text"
                                                    id="loanRate"
                                                    className={inputClasses}
                                                    placeholder="Taux en %"
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace('%', '');
                                                        setTempTaux(value);
                                                    }}
                                                    onBlur={(e) => {
                                                        const value = parseFloat(e.target.value.replace(',', '.'));
                                                        const formattedValue = !isNaN(value) ? value.toFixed(2) : '';
                                                        setUserLoanTaux(Number(formattedValue));
                                                        setTempTaux(formattedValue.replace('.', ','));
                                                    }}
                                                    value={tempTaux}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="loanDuration" className={labelClasses}>
                                                    Durée de l'emprunt (années)
                                                </label>
                                                <input
                                                    type="number"
                                                    id="loanDuration"
                                                    className={inputClasses}
                                                    placeholder="Nombre d'années"
                                                    onChange={(e) => {
                                                        if (/^\d*$/.test(e.target.value)) {
                                                            setUserLoanNumberOfYear(Number(e.target.value))
                                                        }
                                                    }}
                                                    value={userLoanNumberOfYear}
                                                    min="1"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Section Fiscale */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">6</span>
                                Véhicule Personnel
                            </h2>

                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="personalVehicle" className={labelClasses}>
                                        Utilisation du véhicule personnel<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        id="personalVehicle"
                                        className={selectClasses}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === 'OUI') {
                                                setUserUsePersonalVehicle("OUI");
                                                setShowPersonalVehicleFields(true);
                                            } else {
                                                setUserUsePersonalVehicle("NON");
                                                setShowPersonalVehicleFields(false);
                                                setUserNumberOfTaxHorses(0);
                                                setUserNumberOfAnnualProfessionalKM(0);
                                            }
                                        }}
                                        value={userUsePersonalVehicle}
                                        required
                                    >
                                        <option value="">Sélectionner</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>

                                {showPersonalVehicleFields && (
                                    <div className="space-y-6 animate-fadeIn">
                                        <div>
                                            <label htmlFor="taxHorses" className={labelClasses}>
                                                Nombre de chevaux fiscaux<span className="text-red-500 ml-1">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="taxHorses"
                                                className={inputClasses}
                                                placeholder="Nombre de chevaux fiscaux"
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        setUserNumberOfTaxHorses(Number(e.target.value));
                                                    }
                                                }}
                                                value={userNumberOfTaxHorses}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="professionalKM" className={labelClasses}>
                                                Kilomètres professionnels annuels estimés<span className="text-red-500 ml-1">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="professionalKM"
                                                className={inputClasses}
                                                placeholder="Nombre de kilomètres professionnels annuels"
                                                onChange={(e) => {
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        setUserNumberOfAnnualProfessionalKM(Number(e.target.value));
                                                    }
                                                }}
                                                value={userNumberOfAnnualProfessionalKM}
                                                required
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Section Fiscale */}
                        <div className={sectionClasses}>
                            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
                                <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 text-sm">7</span>
                                Informations Fiscales
                            </h2>

                            <div className="space-y-6">
                                {/* Parts fiscales */}
                                <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                    <label htmlFor="fiscalParts" className={labelClasses}>
                                        Nombre de parts de votre foyer fiscal<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    <select
                                        className={`${selectClasses} part-foyer`}
                                        id="fiscalParts"
                                        required
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^(\d+(\.\d{0,1})?)?$/.test(inputValue)) {
                                                const value = inputValue !== "" ? parseFloat(inputValue) : null;
                                                setUserNumberOfFiscalPart(value);
                                            }
                                        }}
                                        value={userNumberOfFiscalPart !== null ? userNumberOfFiscalPart : ''}
                                    >
                                        {[1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* Autres revenus du foyer */}
                                <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                                    <label htmlFor="householdIncome" className={labelClasses}>
                                        Autres salaires, pensions retraite ou indemnités chômage du foyer (annuels)
                                    </label>
                                    <input
                                        type="number"
                                        id="householdIncome"
                                        className={inputClasses}
                                        placeholder="Montant annuel"
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value)) {
                                                setUserOtherSalariedIncomeOfYourHousehold(Number(e.target.value))
                                            }
                                        }}
                                        value={userOtherSalariedIncomeOfYourHousehold}
                                    />
                                </div>

                                {/* Autres revenus imposables */}
                                <div className="space-y-4">
                                    <label htmlFor="revenueType" className={labelClasses}>
                                        Autres revenus imposables du foyer<span className="text-red-500 ml-1">*</span>
                                    </label>
                                    
                                    <Select
                                        isMulti
                                        name="revenues"
                                        options={colourOptions}
                                        styles={{
                                            ...customStyles,
                                            control: (base: any) => ({
                                                ...base,
                                                backgroundColor: 'white',
                                                borderColor: '#E5E7EB',
                                                borderWidth: '2px',
                                                borderRadius: '0.5rem',
                                                '&:hover': {
                                                    borderColor: '#3B82F6'
                                                }
                                            }),
                                            multiValue: (base: any) => ({
                                                ...base,
                                                backgroundColor: '#EFF6FF',
                                                borderRadius: '0.375rem',
                                            }),
                                            multiValueLabel: (base: any) => ({
                                                ...base,
                                                color: '#1D4ED8',
                                                fontWeight: '500',
                                                padding: '0.25rem 0.5rem',
                                            })
                                        }}
                                        classNamePrefix="select"
                                        onChange={handleRevenueSelect}
                                        value={colourOptions.filter(option =>
                                            selectedRevenues.some(revenue => removeAccents(revenue.type) === removeAccents(option.label))
                                        )}
                                    />

                                    {selectedRevenues.map((revenue, index) => (
                                        <div key={index} className="p-6 bg-white rounded-lg border border-gray-200 shadow-sm">
                                            <label htmlFor={`revenueAmount-${index}`} className={labelClasses}>
                                                Montant pour {revenue.type}
                                            </label>
                                            <input
                                                type="number"
                                                id={`revenueAmount-${index}`}
                                                className={inputClasses}
                                                placeholder={`Montant pour ${revenue.type}`}
                                                onChange={(e) => handleRevenueAmountChange(index, e.target.value)}
                                                value={revenue.amount}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Bouton de soumission modifié */}
                        <div className="mt-8">
                            {errorMessage && (
                                <div className="p-4 mb-6 bg-red-50 border border-red-200 rounded-lg">
                                    <p className="text-red-600 text-center font-medium">{errorMessage}</p>
                                </div>
                            )}

                            <ShimmerButton onClick={(event) => handleSubmit(event)}
                                className={`bg-purple-500 hover:bg-blue-600 text-white font-bold py-4 px-4 mt-10 rounded-3xl transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 mx-auto block mb-16 ${loading ? 'opacity-50' : ''}`}
                                disabled={loading && errorMessage === ""}>
                                Voir le résultat
                            </ShimmerButton>

                            {loading && (
                                <div className="loading-overlay">
                                    <div className="loader-container">
                                        <div className="loader">
                                            <div id="first">
                                                <div id="second">
                                                    <div id="third">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="loading-text">Nous recherchons la meilleure solution à votre projet</p>
                                </div>
                            )}
                        </div>

                        {/* Modal et loader restent identiques */}
                        {open && (
                            <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm flex items-center justify-center z-50">
                                <div className="bg-white rounded-xl max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto p-6">
                                    <div className="flex justify-end mb-4">
                                        <Button 
                                            variant="contained" 
                                            onClick={handleClose}
                                            className="bg-blue-600 hover:bg-blue-700"
                                        >
                                            Fermer
                                        </Button>
                                    </div>
                                    
                                    {loading ? (
                                        <div className="loading-overlay">
                                            <div className="loader-container">
                                                <div className="loader">
                                                    <div id="first">
                                                        <div id="second">
                                                            <div id="third"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="loading-text">Nous recherchons la meilleure solution à votre projet</p>
                                            </div>
                                        </div>
                                    ) : null}
                                    
                                    {responseData && (
                                        <DataTable data={responseData} userData={userData} />
                                    )}
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Forms;