import React, { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ShimmerButton from '../components/magicui/shimmer-button';

const Contact = () => {
    const [, setIsMobile] = useState(false);
    const navigate = useNavigate();

    const handleInvalid = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        (e.target as HTMLInputElement).setCustomValidity("Champ obligatoire");
    };

    const handleInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        (e.target as HTMLInputElement).setCustomValidity("");
    };

    const [form, setForm] = useState({
        name: "",
        prenom: "",
        email: "",
        phone: "",
        message: "",
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const isMobile = width < 768;
            setIsMobile(isMobile);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch(' https://fbconseil-c706cd2b1837.herokuapp.com/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: form.prenom,
                    name: form.name,
                    email: form.email,
                    phone: form.phone,
                    message: form.message,
                }),
            });

            const result = await response.json();

            if (result.success) {
                setForm({
                    name: "",
                    prenom: "",
                    email: "",
                    phone: "",
                    message: "",
                });

                confirmAlert({
                    title: 'Merci pour votre message',
                    message: 'Nous reviendrons vers vous dès que possible.',
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {
                                navigate('/');
                            }
                        }
                    ]
                });
            } else {
                console.error('Failed to send email:', result.message);
                alert("Ah, quelque chose s'est mal passé. Veuillez réessayer.");
            }
        } catch (error) {
            console.error('Error:', error);
            alert("Ah, quelque chose s'est mal passé. Veuillez réessayer.");
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto mt-20">
                {/* En-tête avec effet de soulignement amélioré */}
                <div className="text-center mb-16 relative">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-24 h-24 bg-blue-100 rounded-full opacity-50 blur-xl"></div>
                    </div>
                    <h1 className="text-4xl font-bold text-gray-900 mb-4 relative">
                        Contactez-nous
                    </h1>
                    <div className="h-1 w-20 bg-gradient-to-r from-blue-400 to-blue-600 mx-auto rounded-full"></div>
                </div>

                <div className="grid lg:grid-cols-2 gap-16 items-start">
                    {/* Formulaire */}
                    <div className="bg-white rounded-2xl shadow-xl p-8 relative">
                        {/* Effet de brillance en haut - ajusté avec z-index négatif */}
                        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 w-3/4 h-1/2 bg-gradient-to-b from-blue-200 to-transparent opacity-20 blur-xl rounded-full -z-10"></div>
                        
                        <form onSubmit={handleSubmit} className="space-y-8 relative z-10">
                            {/* Nom et Prénom */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                                        Nom<span className="text-blue-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        required
                                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50"
                                        placeholder="Votre nom"
                                        onInvalid={handleInvalid}
                                        onInput={handleInput}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                                        Prénom<span className="text-blue-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="prenom"
                                        required
                                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50"
                                        placeholder="Votre prénom"
                                        onInvalid={handleInvalid}
                                        onInput={handleInput}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            {/* Email et Téléphone */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                                        Email<span className="text-blue-500 ml-1">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50"
                                        placeholder="vous@exemple.com"
                                        onInvalid={handleInvalid}
                                        onInput={handleInput}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-semibold text-gray-700 mb-2">
                                        Téléphone
                                    </label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50"
                                        placeholder="Votre numéro"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            {/* Message */}
                            <div>
                                <label className="block text-sm font-semibold text-gray-700 mb-2">
                                    Message<span className="text-blue-500 ml-1">*</span>
                                </label>
                                <textarea
                                    name="message"
                                    required
                                    rows={6}
                                    className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 bg-gray-50 resize-none"
                                    placeholder="Votre message..."
                                    onInvalid={handleInvalid}
                                    onInput={handleInput}
                                    onChange={handleInputChange}
                                />
                            </div>

                            {/* Bouton d'envoi modifié */}
                            <div className="flex justify-center">
                                <ShimmerButton className="px-8 py-3 rounded-xl text-base font-medium">
                                    Envoyer votre message
                                </ShimmerButton>
                            </div>
                        </form>
                    </div>

                    {/* Section À propos avec image */}
                    <div className="bg-white rounded-2xl shadow-xl p-8 space-y-4 relative h-auto lg:h-[600px]">
                        {/* Effet d'ombre supérieur */}
                        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 w-3/4 h-1/2 bg-gradient-to-b from-blue-200 to-transparent opacity-20 blur-xl rounded-full"></div>
                        
                        {/* Titre */}
                        <h2 className="text-2xl font-bold text-gray-900 relative mb-2">
                            À propos de nous
                        </h2>

                        {/* Photo avec effets */}
                        <div className="relative group h-[180px] md:h-[220px]">
                            <div className="absolute inset-0 bg-gradient-to-t from-gray-50 to-transparent opacity-30"></div>
                            <img 
                                src='/assets/Fannie.png' 
                                alt="Fannie" 
                                className="w-full h-full object-contain rounded-2xl transition-all duration-300 group-hover:brightness-105"
                                style={{
                                    filter: 'drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1))',
                                }}
                            />
                            {/* Effet de brillance */}
                            <div className="absolute -inset-1 bg-gradient-to-r from-blue-400 to-blue-600 opacity-20 blur-xl rounded-full transform -rotate-12 group-hover:opacity-30 transition-opacity duration-300"></div>
                        </div>

                        {/* Texte */}
                        <div className="space-y-2 text-gray-600 leading-relaxed text-base mt-6 md:mt-12">
                            <p className="relative z-10 text-sm md:text-base">
                                Nous sommes deux experts en conseil pour la création d'entreprise, avec plus de 10 années d'expérience en cabinet d'expertise comptable.
                            </p>
                            <p className="relative z-10 text-sm md:text-base">
                                Notre mission est de vous accompagner dans le choix crucial de votre forme juridique, étape fondamentale pour bien démarrer votre aventure entrepreneuriale.
                            </p>
                            <p className="relative z-10 text-sm md:text-base">
                                C'est dans cet esprit que nous avons créé maformejuridique.fr, pour mettre notre expertise à votre service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
