import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { Helmet } from "react-helmet";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ShimmerButton from '../components/magicui/shimmer-button';
import { CookieBanner } from '../components/CookieBanner';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-78TF44GTX6');
ReactGA.send({ hitType: 'pageview', page: 'window.location.pathname' });

const Homes = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 1024 && window.innerWidth < 1440);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1440);
  const { scrollYProgress } = useViewportScroll();
  const scaleX = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShowCookieBanner(true);
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieBanner(false);
  };

  const handleRejectCookies = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowCookieBanner(false);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/Form');
    }, 1000);
  };

  const handleLegalClick = () => {
    navigate('/mentions-legales');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsLaptop(window.innerWidth >= 1024 && window.innerWidth < 1440);
      setIsDesktop(window.innerWidth >= 1440);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {showCookieBanner && <CookieBanner onAccept={handleAcceptCookies} onReject={handleRejectCookies} />}
      <Helmet>
        {/* Helmet info */}
      </Helmet>
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <>
          {isMobile && (
            <motion.div className="progress-bar" style={{ scaleX, originX: 0, scaleY: 2, backgroundColor: "#1D63FF" }} />
          )}
          <div className="overflow-hidden">
            <div className="fixed inset-0 bg-gradient-custom -z-10" />
            
            <div className={`flex flex-col ${isTablet ? 'items-center' : 'md:flex-row items-center md:items-start'} md:pt-10 mx-4 md:mx-10`}>
              <div id="home" className="flex flex-col justify-center items-center md:items-start w-full md:w-3/4 px-8 mt-12 relative">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <h1 className={`text-gray-800 mt-20 ${isDesktop ? 'text-4xl' : isLaptop ? 'text-3xl' : 'text-2xl'} font-bold text-center md:text-left leading-tight`}>
                    Vous réfléchissez à la création de votre entreprise?
                  </h1>
                  <br />
                  <p className={`text-gray-700 ${isDesktop ? 'text-2xl' : isLaptop ? 'text-xl' : 'text-lg'} text-center md:text-left -mt-6`}>
                    Grâce à notre expertise nous vous accompagnons dans :
                  </p>

                  <div className="space-y-4 mt-8">
                    {[
                      "Le choix de la forme juridique",
                      "La réalisation du budget prévisionnel",
                      "La rédaction de vos statuts"
                    ].map((item, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.2 }}
                        className="flex items-center gap-4 p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border-l-4 border-[#4B7BF5]/40"
                      >
                        <div className="flex-shrink-0 w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-2xl">🔵</span>
                        </div>
                        <span className="text-gray-800 font-semibold text-lg">{item}</span>
                      </motion.div>
                    ))}
                  </div>

                  <div className="mt-16 w-full max-w-5xl mx-auto">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                      className="bg-gradient-to-br from-white to-blue-50 p-12 rounded-3xl shadow-lg border border-blue-100"
                    >
                      <div className="space-y-4">
                        <h2 className="text-center">
                          <span className="block text-3xl lg:text-4xl font-bold text-gray-800">
                            Trouvez ou confirmez la forme juridique la plus adaptée
                          </span>
                          <span className="block text-xl lg:text-2xl text-gray-600 font-medium mt-4">
                            à votre projet, à votre situation personnelle et à vos besoins
                          </span>
                          <span className="block text-3xl lg:text-4xl font-extrabold text-[#4B7BF5] mt-6">
                            grâce à notre SIMULATEUR!
                          </span>
                        </h2>

                        <div className="mt-12 flex justify-center">
                          <ShimmerButton 
                            onClick={handleClick}
                            className="text-xl font-semibold px-14 py-5 rounded-full bg-[#4B7BF5] hover:bg-[#3D63D2] transform hover:scale-105 transition-all duration-300 shadow-lg"
                          >
                            Commencer la simulation
                          </ShimmerButton>
                        </div>

                        <div className="mt-16 bg-white/80 backdrop-blur-sm rounded-2xl p-10 shadow-inner">
                          <p className="text-gray-800 text-2xl font-semibold mb-8 text-center">
                            À l'issue de cette simulation, vous pourrez :
                          </p>
                          <div className="grid gap-6 max-w-2xl mx-auto">
                            {[
                              {
                                icon: "📊",
                                text: "Obtenir votre Budget Prévisionnel issu de la simulation"
                              },
                              {
                                icon: "👥",
                                text: "Prendre rendez-vous avec un expert en création"
                              },
                              {
                                icon: "📝",
                                text: "Aller plus loin et rédiger vos Statuts"
                              }
                            ].map((item, index) => (
                              <motion.div
                                key={index}
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: index * 0.2 }}
                                className="flex items-center gap-6 p-4 hover:bg-blue-50 rounded-xl transition-colors duration-300"
                              >
                                <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                                  <span className="text-2xl">{item.icon}</span>
                                </div>
                                <span className="text-gray-700 text-lg font-medium">{item.text}</span>
                              </motion.div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </motion.div>
              </div>

              <img
                src='/assets/Illust.svg'
                alt="home"
                className={`lg:ml-auto lg:mt-20 lg:mr-0 rounded-lg ${isDesktop ? 'w-[42vw]' : isLaptop ? 'w-[30vw]' : 'w-full'} ${isMobile ? 'order-last mt-4' : ''}`}
                style={{ display: 'block', marginRight: 0, position: isMobile ? 'static' : 'absolute', right: 0 }}
              />
            </div>
            <footer className="fixed bottom-0 left-0 right-0 bg-white/90 backdrop-blur-sm border-t border-gray-100 z-50">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row justify-between items-center py-4 space-y-2 sm:space-y-0">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500">© 2024</span>
                    <span className="text-sm font-medium text-gray-700">FBCONSEILS - SIRET 931 237 192</span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={handleLegalClick}
                      className="text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200 px-3 py-2 rounded-md hover:bg-gray-50"
                    >
                      Mentions Légales
                    </button>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
};

export default Homes