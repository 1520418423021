import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calculator, Scales, ChartBar, House, QuestionMark, PaperPlaneTilt } from "@phosphor-icons/react";

const Nav: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    // Force le défilement en haut à chaque changement de route
    window.scrollTo(0, 0);
  }, [location]);
  
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const [isOpen, setIsOpen] = useState(false);
  const [elementFocused, setElementFocused] = useState<number | null>(null);

  const navigate = useNavigate();

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 1280);
  }, []);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setIsScrolled(currentScrollY > 50);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, handleResize]);

  const navigateToSection = (sectionId: string) => {
    // Forcer le défilement en haut de la page immédiatement
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    
    if (window.location.pathname === '/') {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      navigate(`/#${sectionId}`);
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); // Assurez-vous d'être en haut après la navigation
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
    setIsOpen(false);
  };
  
  

  const handleMouseMove = (index: number) => {
    setElementFocused(index);
  };

  const handleMouseLeave = () => {
    setElementFocused(null);
  };

  return (
    <>
      <main className={`${isMobile ? 'content-margin-mobile' : ''} overflow-x-hidden`} id="home">
        <nav
          id='navbar'
          className={`fixed top-0 left-0 right-0 w-full h-16 z-50 transition-all duration-300 py-12 ${
            isScrolled ? 'bg-white shadow-lg' : 'bg-white/80 backdrop-blur-lg'
          } ${isMobile ? 'mobile-nav' : ''}`}
        >
          <div className="container mx-auto px-4 flex justify-between items-center text-[#1D63FF] h-full max-w-full">
            <div className="flex items-center flex-shrink-0">
              <img
                src='/img/B__1_-removebg-preview.png'
                alt="logo"
                className='hover:scale-110 transform transition-all duration-500 ease-in-out w-auto h-[4rem] sm:h-[5rem] md:h-[7rem] mt-2 cursor-pointer'
                onClick={() => navigateToSection('home')}
              />
            </div>
            <div className={`rounded-hover-container flex-grow flex items-center justify-end space-x-4 ${isMobile ? 'hidden' : 'flex'}`} onMouseLeave={handleMouseLeave}>
              {[
                { path: "/", icon: <House size={25} />, label: "Accueil" },
                { path: "/juridique", label: "FORME JURIDIQUE" },
                { path: "/previsionnel", label: "BUDGET PREVISIONNEL" },
                { path: "/statuts", label: "STATUTS" },
                { path: "/fonctionnement", label: "FONCTIONNEMENT" },
                { path: "/contact", label: "CONTACT" }
              ].map((item, index) => (
                <Link
                  to={item.path}
                  key={item.label}
                  className={`rounded-hover px-3 py-2 text-base font-medium relative z-10 ${
                    elementFocused === index ? 'text-white' : 'text-[#1D63FF]'
                  }`}
                  onMouseEnter={() => handleMouseMove(index)}
                >
                  {item.icon ? item.icon : item.label}
                  <AnimatePresence>
                    {elementFocused === index && (
                      <motion.div
                        className="absolute inset-0 bg-[#1D63FF] rounded-md -z-10"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{
                          duration: 0.3,
                          ease: [0.25, 0.1, 0.25, 1], // Courbe d'accélération pour une transition fluide
                        }}
                        layoutId="hover-bg"
                        layout
                      />
                    )}
                  </AnimatePresence>
                </Link>
              ))}
            </div>
            <div className={`${isMobile ? "flex justify-end items-center" : "hidden"} ml-auto pr-2`}>
              <button onClick={() => setIsOpen(!isOpen)} className="text-2xl p-2">
                {isOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>
          </div>
        </nav>
        
        {/* Sidenav mobile */}
        <motion.div
          initial={false}
          animate={isOpen ? "open" : "closed"}
          variants={{
            open: { opacity: 1, x: 0, transition: { duration: 0.3, ease: "easeOut" } },
            closed: { opacity: 0, x: '-100%', transition: { duration: 0.3, ease: "easeIn" } }
          }}
          className={`fixed top-0 left-0 w-[85%] max-w-[400px] h-full bg-white/95 backdrop-blur-md z-50 
            flex flex-col pt-20 pb-8 px-4
            ${isMobile ? "mobile-menu-open" : "hidden"}`}
        >
          {/* Logo en haut de la sidenav */}
          <div className="absolute top-4 left-4">
            <img
              src='/img/B__1_-removebg-preview.png'
              alt="logo"
              className='w-auto h-12'
            />
          </div>

          {/* Bouton fermeture */}
          <button 
            onClick={() => setIsOpen(false)}
            className="absolute top-6 right-4 text-gray-500 hover:text-blue-600 transition-colors"
          >
            <FaTimes size={20} />
          </button>

          {/* Liens de navigation */}
          <div className="flex flex-col space-y-4 mt-6 overflow-y-auto">
            {[
              { path: "/", icon: <House weight="bold" />, label: "Accueil" },
              { path: "/juridique", icon: <Scales weight="bold" />, label: "Forme juridique" },
              { path: "/previsionnel", icon: <Calculator weight="bold" />, label: "Budget prévisionnel" },
              { path: "/statuts", icon: <ChartBar weight="bold" />, label: "Statuts" },
              { path: "/fonctionnement", icon: <QuestionMark weight="bold" />, label: "Fonctionnement" },
              { path: "/contact", icon: <PaperPlaneTilt weight="bold" />, label: "Contact" }
            ].map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={() => {
                  setIsOpen(false);
                  navigateToSection(item.path.slice(1) || 'home');
                }}
                className="flex items-center space-x-3 px-3 py-2.5 rounded-lg
                  text-gray-600 hover:text-blue-600 hover:bg-blue-50
                  transition-all duration-200 ease-in-out"
              >
                <span className="text-xl">{item.icon}</span>
                <span className="text-sm font-medium">{item.label}</span>
              </Link>
            ))}
          </div>
        </motion.div>

        {/* Overlay */}
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
            onClick={() => setIsOpen(false)}
          />
        )}
      </main>
    </>
  );
};

export default Nav;
