import React, { RefObject, useState, useEffect, FC } from 'react';
import { motion } from 'framer-motion';
import { useNavigate} from 'react-router-dom';
import ShimmerButton from './magicui/shimmer-button';

interface StepType {
  number: number;
  title: string;
  description: string;
  emoji: string;
}

const steps: StepType[] = [
  {
    number: 1,
    title: 'Comprendre votre projet',
    description: 'Répondez à quelques questions simples sur notre simulateur pour nous aider à mieux comprendre votre projet et vos objectifs. Nous vous demandons des informations telles que le chiffre d’affaires, les investissements, les apports, votre salaire souhaité, etc.',
    emoji: '📝',
  },
  {
    number: 2,
    title: 'Analyse des besoins',
    description: 'Grâce à notre solution et notre expertise en création d’entreprise, nous étudions vos réponses et réalisons un budget prévisionnel avec un tableau de trésorerie pour chacune des formes juridiques possibles. Nous comparons les coûts en termes de charges sociales, impôts professionnels et impôts personnels générés. Nous identifions la viabilité du projet en validant la croissance de la trésorerie notamment.',
    emoji: '🔍',
  },
  {
    number: 3,
    title: 'Recommandation personnalisée',
    description: 'Une fois la simulation faite et le résultat déterminé, nous vous indiquons immédiatement la forme juridique la mieux adaptée à votre situation.',
    emoji: '💡',
  },
  {
    number: 4,
    title: 'Planification financière',
    description: 'Pour obtenir un budget prévisionnel pour la forme juridique recommandée et/ou planifier votre avenir financier avec plus de confiance, deux possibilités:',
    emoji: '📈',
  },
  {
    number: 5,
    title: 'Rédaction des statuts',
    description: 'Afin de vous accompagner jusqu’au bout dans le lancement de votre projet, vous pouvez également à l’issue de votre budget prévisionnel obtenir les statuts de votre société.',
    emoji: '📝',
  },
];

const handlePayment1 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, navigate: ReturnType<typeof useNavigate>) => {
  event.preventDefault();
  navigate('/Form');
};

const handlePayment2 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  event.preventDefault();
  window.open('https://calendly.com/fbsimulateurs/rendez-vous-conseils-budget-previsionnel', '_blank');
};

const useOnScreen = (ref: RefObject<Element>): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '-20% 0px -20% 0px',
        threshold: 0.1
      }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return isIntersecting;
};

interface StepProps {
  step: StepType;
  index: number;
}

const Step: FC<StepProps> = ({ step, index }) => {
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(ref);
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  const isActive = index === 0 || (onScreen || hasBeenVisible);

  useEffect(() => {
    if (onScreen) {
      setHasBeenVisible(true);
    }
  }, [onScreen]);

  return (
    <motion.div
      ref={ref}
      className="relative flex items-start mb-20"
    >
      {index < steps.length - 1 && (
        <div className="absolute left-6 w-0.5 bg-gray-200" style={{ top: '3rem', height: 'calc(100% + 2rem)' }}>
          <motion.div
            className="absolute top-0 left-0 w-full bg-blue-600"
            initial={{ height: '0%' }}
            animate={{ 
              height: isActive ? '100%' : '0%'
            }}
            transition={{ 
              duration: 0.8,
              ease: "easeInOut",
              delay: 0.2
            }}
          />
        </div>
      )}

      <div className="relative z-10">
        <motion.div
          className={`w-12 h-12 rounded-full flex items-center justify-center shadow-lg border-2
            transition-all duration-500 ${isActive
              ? 'bg-blue-600 border-blue-600' 
              : 'bg-white border-gray-300'}`}
        >
          <span className={`font-bold text-lg transition-colors duration-500
            ${isActive ? 'text-white' : 'text-gray-400'}`}>
            {step.number}
          </span>
        </motion.div>
      </div>

      <div className="flex flex-col ml-6 pt-1">
        <h3 className={`text-xl font-semibold mb-3 flex items-center
          ${isActive ? 'text-gray-800' : 'text-gray-400'}`}>
          <span className="mr-3 text-2xl">{step.emoji}</span>
          {step.title}
        </h3>
        <div className={`rounded-xl p-6 shadow-sm border transition-all duration-500
          ${isActive 
            ? 'bg-white border-gray-100 hover:shadow-md' 
            : 'bg-gray-50 border-gray-200'}`}>
          <p className={`leading-relaxed ${isActive ? 'text-gray-600' : 'text-gray-400'}`}>
            {step.description}
            {step.number === 4 && (
              <div className={`mt-6 space-y-6 transition-opacity duration-500
                ${onScreen || hasBeenVisible ? 'opacity-100' : 'opacity-50'}`}>
                <div className="bg-blue-50 rounded-lg p-6">
                  <p className="text-gray-700 mb-4">
                    Vous souhaitez récupérer directement votre budget prévisionnel issu de votre simulation réalisée au tarif exceptionnel de <span className="font-semibold text-blue-600">50€ HT</span>.
                  </p>
                  <div className="flex justify-center">
                    <ShimmerButton
                      onClick={(event) => handlePayment1(event, navigate)}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2.5 px-6 rounded-lg"
                    >
                      Démarrer ma simulation
                    </ShimmerButton>
                  </div>
                </div>

                <div className="flex justify-center">
                  <span className="px-4 py-2 bg-gray-100 rounded-full text-gray-600 font-medium">OU</span>
                </div>

                <div className="bg-gray-50 rounded-lg p-6">
                  <p className="text-gray-700 mb-4">
                    Vous souhaitez prendre un rendez-vous avec nous pour en discuter, personnaliser de manière encore plus précise votre budget prévisionnel au tarif de <span className="font-semibold text-blue-600">120€ HT</span>.
                  </p>
                  <div className="flex justify-center">
                    <ShimmerButton
                      onClick={handlePayment2}
                      className="bg-gray-800 hover:bg-gray-900 text-white font-medium py-2.5 px-6 rounded-lg"
                    >
                      Prendre RDV
                    </ShimmerButton>
                  </div>
                </div>
              </div>
            )}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

const Roadmap: FC = () => {
  return (
    <div id="fonctionnement" className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
      <motion.h2 
        className="text-3xl sm:text-4xl font-bold text-gray-900 text-center mb-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Comment ça marche ?
      </motion.h2>
      <div className="relative">
        {steps.map((step, index) => (
          <Step key={step.number} step={step} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
