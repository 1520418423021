import './styles/index.css';
import Nav from './components/NavBar';
import Homes from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Forms from './pages/Simulation';
import Contact from './pages/Contact';
import CardsContainer from './components/Card/CardsContainer';
import { Previsionnel } from './pages/Previsionnel';
import Status from './pages/Status';
import Success from './components/Success';
import Fonction from './components/RoadMap';
import Footer from './components/Footer';
import FormJuridic from './pages/FormJuridic';
import MentionsLegales from './pages/MentionsLegales';

import { Helmet } from "react-helmet";


const App = () => {







  return (

    <>
      <Helmet>
       
        <meta name="description" content="Que vous soyez un artisan, un commerçant, un libéral ou un consultant, notre simulateur vous aide à choisir la forme juridique idéale pour créer votre entreprise ou société. Profitez d'une simulation précise pour orienter votre décision et planifier votre budget prévisionnel." />
      </Helmet>

      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Homes />} />
          <Route path="/form" element={<Forms />} />
          <Route path="/statuts" element={<Status />} />
          <Route path="/success" element={<Success />} />
          <Route path="/fonctionnement" element={<Fonction />} />
          <Route path="/cards" element={<CardsContainer />} />
          <Route path="/previsionnel" element={<Previsionnel />} />
          <Route path="/juridique" element={<FormJuridic />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />







        </Routes>
        <div className="footer-wrapper mt-28">
          <Footer />
        </div>
      </Router>

    </>
  );
};

export default App;
